import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout";
import PostLink from "../components/post-link";
import SEO from "../components/seo";

type PropType = {
  data: {
    allMarkdownRemark: {
      edges: {
        node: {
          id: string;
          excerpt: string;
          fileAbsolutePath: string;
          frontmatter: {
            date: string;
            slug: string;
            title: string;
            categories: string[];
          };
        };
      }[];
    };
  };
};

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}: PropType) => {
  const Posts = edges
    .filter((edge) => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map((edge) => <PostLink key={edge.node.id} {...edge.node} />);

  return (
    <Layout noContentPadding={true}>
      <SEO
        post={{
          title: "",
          path: "",
        }}
      />
      <Hero />
      <section className="col col-12 col-xl-8 mx-auto">
        {Posts}
        <Link to="/posts" className="btn btn-secondary" role="button">
          All posts
        </Link>
      </section>
    </Layout>
  );
};
export default IndexPage;

function Hero() {
  return (
    <div style={{ display: "grid" }} className="text-white pb-4">
      <StaticImage
        src="../images/main-hero.jpg"
        alt="Happy coffee"
        placeholder="blurred"
        style={{
          gridArea: "1/1",
          maxHeight: 400,
        }}
        layout="fullWidth"
      />
      <div
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: "1/1",
          position: "relative",
          // This centers the other elements inside the hero component
          placeItems: "center",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          backgroundColor: "#0003",
        }}
      >
        <h1 className="display-5 hero-title">goof by Patrick</h1>
        <p className="lead mb-4 text-center">
          Converting ideas, experience and caffeine ☕
          <br />
          into solutions, code and utility ✔️
        </p>
      </div>
    </div>
  );
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 5
    ) {
      edges {
        node {
          id
          fileAbsolutePath
          excerpt(pruneLength: 300)
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            slug
            title
            categories
          }
        }
      }
    }
  }
`;
